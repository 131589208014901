::after, ::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.dashboard-logo{
    position: relative;
}

div#dashboard div.menu:hover .dashboard-tasks-count {
    background-color: #ffab0d;
}

.dashboard-logo .dashboard-tasks-count{
    bottom: 0;
    right: 0;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    color: white;
    background-color: #004735;
    transition: background-color 0.5s;
}

.dashboard-logo .favorite{
    top: 0;
    right: 0;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    color: white;
    border: 1px solid #004735;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
}

div#dashboard div.menu:hover .favorite{
    opacity: 1;
    visibility: visible;
}

table.tasks-table > thead > tr > th.system-name{
    width: 350px;
}
table.tasks-table > thead > tr > th.priority{
    width: 200px;
}

table.tasks-table > thead > tr > th:nth-child(3){
    width: 180px;
}
table.tasks-table > tbody > tr > td > div{
    display: table-cell;
    vertical-align: middle;
}

table.tasks-table > tbody > tr > td.priority > div{
    padding-left: 5px;
}

table.tasks-table > tbody > tr > td.priority::before{
    width: 32px;
    height: 32px;
    content: " ";
    margin: 5px;
    display: table-cell;
}

table.tasks-table > tbody > tr > td.priority.priority-1::before{
    background-color: rgb(0,255,0);
}

table.tasks-table > tbody > tr > td.priority.priority-2::before{
    background-color: rgb(64,191,0);
}

table.tasks-table > tbody > tr > td.priority.priority-3::before{
    background-color: rgb(127,127,0);
}

table.tasks-table > tbody > tr > td.priority.priority-4::before{
    background-color: rgb(191,64,0);
}
table.tasks-table > tbody > tr > td.priority.priority-5::before{
    background-color: rgb(255,0,0);
}

table.tasks-table > tbody > tr:last-child.loading-more-tasks > td{
    border-bottom: 0;
}

table.tasks-table > tbody > tr:last-child.loading-more-tasks.loading > td{
    line-height: 32px;
    font-size: 20px;
}
table.tasks-table > tbody > tr:last-child.loading-more-tasks > td > div{
    text-align: center;
    display: block;
}

.removed-widget{
    opacity: 0.5;
    font-style: italic;
}

.edited-widget{
    font-weight: bold;
}

.widget-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding-top: 40px;
    padding-right: 40px;
    position: relative;
}

.widget {
    position: relative;
    box-shadow: 0 0 4px #AAAAAA;
    background-color: rgba(0,71,53,0.025);
/*    background-color: white;*/
    border: 2px solid white;
/*    border-radius: 20px;*/

    background-image:
        linear-gradient(to top,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 15px),
        /*        linear-gradient(to left,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 10%),
        linear-gradient(to right,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 10%),*/
        linear-gradient(to bottom,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 15px)
        ;
}
.widget.system{
    display: grid;
    grid-template-rows: 40px auto 40px;
    cursor: pointer;
    transition: all linear 0.3s;
    background-color: rgba(0, 71, 53, 0.1);
    background-image:
        linear-gradient(to top,  rgba(0, 116, 86, 0.1) 0%, rgba(0, 116, 86, 0.06) 5px, rgba(0, 116, 86, 0) 15px),
        /*        linear-gradient(to left,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 10%),
        linear-gradient(to right,  rgba(0, 150, 79, 0.05) 0%, rgba(0, 150, 79, 0.04) 5px, rgba(0, 150, 79, 0) 10%),*/
        linear-gradient(to bottom,  rgba(0, 116, 86, 0.1) 0%, rgba(0, 116, 86, 0.06) 5px, rgba(0, 116, 86, 0) 15px)
        ;
}

.widget.system:hover{
    background-color: #ffab0d90;
    box-shadow: 0 0 4px #555555;
    transition: none;
}

.widget.system .widget-content{
    align-self: center;

}

.widget.system .widget-content div#dashboard div.menu div.dashboard-title{
    margin: 0;

    font-size: 18px;
}

.widget.system .information-container{
    align-self: start;
    justify-self: right;
}

.widget.system .information-container .info{
    border-radius: 50%;
    border: 2px solid rgb(26, 82, 154);
    color: rgb(26, 82, 154);
    background: white;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    transition: all linear 0.2s;
    margin-top: 4px;
    margin-right: 4px;
    background-image: url('../images/information.svg');
    background-size: 100%;
}

#system-info-bubble{
    position: absolute;
    background-color: #004735;
    color: white;
    z-index: 10000000;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    width: calc(100% - 24px);
    text-align: justify;
    display: block;
    border: 2px solid white;
}

.widget.system .information-container .info:hover + .bubble{
    display: block;
}

.widget.system .widget-content{
    padding: 0 5px;
}

.widget.system .dashboard-title{
    white-space: pre-wrap;
}

.widget.system:hover .information-container .info{
    transition: none;
}


.widget-container.system{
    padding: 0;
}

/*.widget::after{
    content : "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('http://localhost/portal/themes/fold/images/foepulet/02.jpg'); 
    width: 100%;
    height: 100%;
    opacity : 0.1;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}*/

.widget-title{
    text-align: center;
    padding: 10px;
    font-size: 20px;
}

.widget-container-buttons{
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
}

.system-title{
    font-size: 21px;
    margin: 20px 0 10px 0;
}
.system-description{
    text-align: justify;
}

.widget-content{
    padding:10px;    
}

.widget-data-number{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    margin: 0 auto;
    box-shadow: 0 0 3pt 2pt #004735;
}

.aktiv-beszamolo-table .more-data{
    text-align: center;
}

.label + div.full-input  .field{
    width: 100%;
}

.popup{
    max-height: 400px;
    overflow-y: auto;
}

.col-actions{
    border: none;
    width: 32px;
    background-color: transparent;
}

@media(min-width: 576px) {
    #system-info-bubble{
        width: 426px;
    }
    .widget-container{
        grid-template-columns: repeat(2, 1fr);
    }

    .widget.system{
        grid-template-rows: 35px auto 35px;
    }

    .widget.system .information-container .info{
        width: 25px;
        height: 25px;
    }
}

@media(min-width: 992px) {
    .widget-container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(min-width: 1200px) {
    .widget-container{
        grid-template-columns: repeat(4, 1fr);
    }
}
